import type { PropsWithChildren } from "react";
import { useClerk, useUser } from "@clerk/remix";
import {
  Separator,
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTrigger,
} from "@infinitenz/pyrite";
import { Link, useNavigate } from "@remix-run/react";
import {
  hasApeironAccess,
  hasLoggerAccess,
} from "apeiron-services/src/user/user-roles";

import {
  useRootLoaderData,
  useUserLoaderData,
} from "~/modules/root/hook/useRootLoaderData";

/**
 * Drawer Nav that has no app logic, safe for ErrorBoundaries
 */
export const SafeDrawerNav = ({ children }: PropsWithChildren) => {
  return (
    <Sheet>
      <SheetTrigger className="h-full w-16 min-w-16 rounded-none border-r">
        <i className="ri-menu-line ri-2x text-blue-500" />
      </SheetTrigger>
      <SheetContent side={"left"} className={"overflow-y-scroll p-0"}>
        <SheetHeader className={"p-4"}>
          <Link unstable_viewTransition to="/">
            <img
              src="/logo.svg"
              className="logo"
              alt={"Apeiron"}
              width={130}
              height={40}
            />
          </Link>
        </SheetHeader>
        <Separator />
        <div className={"h-full w-full"}>{children}</div>
        <SheetFooter className={"flex"}>
          <div className="w-full border-gray-200 p-4">
            Built by friendly engineers at{" "}
            <a href="https://infinitystudio.ai" className="text-blue-500">
              Infinity Studio
            </a>
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

/**
 * Drawer Nav that has app logic, not safe for error boundaries
 */
export const DrawerNav = () => {
  const { signOut } = useClerk();

  // Check provider exists before rendering
  const { user: clerkUser } = useUser();
  const navigate = useNavigate();

  const { infinityOrganisationId } = useRootLoaderData();
  const { user } = useUserLoaderData();

  return (
    <SafeDrawerNav>
      {hasLoggerAccess(user.role) && (
        <>
          <Option to={"/"}>
            <div className="ri-home-line ri-xl leading-none" />
            <p>Home</p>
          </Option>

          <Option
            to={"https://infinitystudiohelp.zendesk.com/hc/en-us/"}
            newTab={true}
          >
            <div className="ri-mail-send-line ri-xl leading-none" />
            <p> Request Help</p>
          </Option>
          <Option
            to={"https://infinitystudiohelp.zendesk.com/hc/en-us/"}
            newTab={true}
          >
            <div className="ri-question-line ri-xl leading-none" />
            <p> Support Documentation</p>
          </Option>
          <h4 className="border-b px-4 py-4 font-semibold">Projects</h4>
          <Option to={"/projects"}>
            <div className="ri-folders-line ri-xl leading-none" />
            Projects
          </Option>
          <Option to={"/projects/create"}>
            <div className="ri-folder-add-line ri-xl leading-none" />
            Create Project
          </Option>
        </>
      )}
      {hasApeironAccess(user.role) && (
        <>
          <h4 className="border-b px-4 py-4 font-semibold">Investigations</h4>
          <Option to={"/investigations"}>
            <div className="ri-road-map-line ri-xl leading-none" />
            Investigations
          </Option>
          <Option to={"/investigations/create"}>
            <div className="ri-map-pin-add-line ri-xl leading-none" />
            Upload Investigation
          </Option>
        </>
      )}
      {hasLoggerAccess(user.role) && (
        <Option to={"/logger"}>
          <div className="flex items-center gap-2.5">
            <img src="/icons/logger.png" className="w-6" alt="logger logo" />
            Apeiron Logger
          </div>
        </Option>
      )}
      <div className="border-b p-4">
        <h4 className="font-semibold">Additional Options</h4>
      </div>
      <Option to={"/pricing"}>
        <div className="ri-bill-line ri-xl leading-none" />
        <p>Pricing</p>
      </Option>
      <div className="lg:hidden">
        {(clerkUser?.organizationMemberships.length ?? 0) > 1 && (
          <Option to={"/organisation/selection"}>
            <div className="ri-swap-box-line ri-xl leading-none" />
            Switch Organisation
          </Option>
        )}

        {user.organisationId === infinityOrganisationId && (
          <Option to={"/infinity-guardian"}>
            <div className="ri-error-warning-line ri-xl leading-none" />
            Admin Tools
          </Option>
        )}
      </div>

      <button
        onClick={() =>
          signOut(() =>
            navigate("/login", {
              replace: true,
            }),
          )
        }
        className={
          "flex w-full cursor-pointer items-center gap-x-2 border-b p-4 font-medium text-blue-500 hover:bg-primary-50"
        }
      >
        <div className="ri-logout-box-r-line ri-xl" />
        Sign out
      </button>
    </SafeDrawerNav>
  );
};

const Option = ({
  children,
  to,
  newTab,
}: PropsWithChildren<{ to: string; newTab?: boolean }>) => {
  return (
    <Link
      unstable_viewTransition
      to={to}
      onClick={() => {
        // Close the dropdown menu.. known issue with NextJS + Radix
        document.dispatchEvent(new KeyboardEvent("keydown", { key: "Escape" }));
      }}
      target={newTab ? "_blank" : ""}
    >
      <div
        className={
          "flex flex-row gap-2 border-b p-4 align-middle font-medium text-blue-500 hover:bg-primary-50"
        }
      >
        {children}
      </div>
    </Link>
  );
};
